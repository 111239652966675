.popup {
    position: absolute;
    color: #ffffff;
    width: 463px;
    opacity: 0;
    visibility: hidden;
    transform: translateX(-20px);

    transition: all 0.5s ease;
}

.popup.show {
    opacity: 1;
    visibility: visible;
    transform: translateX(0px);
}

.popup h2 {
    font-size: 1.4rem;
    font-weight: 300;
}

.popup p {
    font-size: 0.9rem;
    font-weight: 300;
}

.popup button {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
    border-radius: 0;
    font-size: 1rem;
    font-weight: 800;
}
