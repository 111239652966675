#div_ingramCircleWhite {
    opacity: 0;
    visibility: hidden;

    transition: all 1s ease;
}

#div_ingramCircleWhite.show {
    opacity: 1;
    visibility: visible;
}

#img_ingramCircleWhite {
    position: absolute;
    z-index: 1000;	/* Set enough z-index to be above any elements that may be under it */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);	/* Offset the object's own dimensions since top/left properties are referencing the upper left corner position */

    transition: all 0.5s ease;
}

#img_ingramCircleWhite.hover {
    transform: translate(-50%, -50%) scale(1.10);
    cursor: pointer;
}

#img_cloudLine {
    position: absolute;
    top: -10px;
    left: -10px;
    height: 250px;

    transition: all 1s ease;
}

#img_cloudLine.hide {
    opacity: 0;
    visibility: hidden;
}

#img_techSolLine {
    position: absolute;
    top: 270px;
    left: 220px;
    height: 250px;

    transition: all 1s ease;
}

#img_techSolLine.hide {
    opacity: 0;
    visibility: hidden;
}

#img_commLifeCycleLine {
    position: absolute;
    top: -10px;
    left: 240px;
    height: 250px;

    transition: all 1s ease;
}

#img_commLifeCycleLine.hide {
    opacity: 0;
    visibility: hidden;
}


#iconlabel_cloud {
    position: absolute;
    top: -90px;
    left: -220px;
}

#iconlabel_cloud .iconLabelTextMargin {
    margin-top: 14px;
}

#iconlabel_commLifeCycle {
    position: absolute;
    top: -90px;
    left: 340px;
}

#iconlabel_techSol {
    position: absolute;
    top: 550px;
    left: 42px;
}

#iconlabel_techSol .iconLabelTextMargin {
    margin-top: 8px;
}

#popup_cloud {
    top: 0px;
    left: -517px;
}

#popup_cloud .popupCopyMargin {
    margin-left: -28px;
}

#popup_cloud .popupButtonMargin {
    margin-left: -13px;
}

#popup_commLifeCycle {
    top: 0px;
    left: 460px;
}

#popup_commLifeCycle .popupCopyMargin {
    margin-left: -28px;
}

#popup_commLifeCycle .popupButtonMargin {
    margin-left: -13px;
}

#popup_techSol {
    top: 480px;
    left: 260px;
}

#popup_techSol .popupCopyMargin {
    margin-left: -28px;
}

#popup_techSol .popupButtonMargin {
    margin-left: -13px;
}
