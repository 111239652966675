.container_pillarBoxes {
    background-color: #000000;
}

.container_pillarBoxes p {
    color: #ffffff;
    font-size: 1.625rem;
    weight: 600;
}

.container_pillarBoxes .border-left {
    border-left: 1px solid #6d6d6d;
}

.container_pillarBoxes .border-right {
    border-right: 1px solid #6d6d6d;
}

.container_pillarBoxes .border-top {
    border-top: 1px solid #6d6d6d;
}

.container_pillarBoxes .border-bottom {
    border-bottom: 1px solid #6d6d6d;
}