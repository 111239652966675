#container_pillars {
    background-color: #ffffff;    
}

#container_pillars p {
    font-weight: 600;    
    font-size: 1.125rem;
}

.gray-border-bottom {
    border-bottom: 1px solid #767676;
}

#div_mobile_hole_base {
    padding-top: 100px;
    padding-bottom: 10px;
    /*mix-blend-mode: hard-light;*/
}

#div_mobile_hole_base p {
    color: #ffffff;
}

/*
#div_mobile_hole {
    width: 200px;
    height: 200px;
    border-radius: 999px;
    background-color: gray;
}*/

#img_mobileIngramCircle {
    
}