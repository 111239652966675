#container_windowSearch {
    transition: all 1s ease;
}

#container_windowSearch.hidden {
    opacity: 0;
    visibility: hidden;
}

#div_mainSearch {
    transition: all 1s ease;
}

#div_mainSearch.hidden {
    opacity: 0;
}

input#input_mainSearch.form-control {
    background-color: transparent;
    color: #ffffff;
    font-size: 2.0rem;
    border-radius: 0;
    border-color: #444444;
    border-right: none;
}

input#input_mainSearch.form-control:focus {
    background-color: transparent;
    color: #ffffff;
}

input#input_mainSearch.form-control::placeholder {
    color: #a5a5a5;
}

.btn-search {
    color: #000000;
    background: #0077d4;
    border-radius: 0;
}

input#input_secondarySearch {
    border-top: transparent;
    border-left: transparent;
    border-right: transparent;
    border-bottom: 2px solid;
    border-radius: 0;
}

input#input_secondarySearch.form-control:focus {
    border-color: transparent;
    box-shadow: none;
    border-bottom: 2px solid;
} 

#img_searchIcon {
    margin-left: -1.2rem;
}

#div_backButton {
    position: absolute;
    right: -8rem;
    top: 4rem;
    
}

#div_backButton img:hover, #div_windowSearchBackButton img:hover {
    cursor: pointer;
}

#div_backButton p {
    color: #ffffff;
    margin-left: 5.6rem;
    margin-top: 0.5rem;
}

#div_backButton p:hover {
    cursor: pointer;
}

.input-group-text {
    background: none;
    border: 1px solid #444444;
    border-left: none;
}

#div_windowSearchBackButton {
    position: absolute;
    top: 4rem;
    left: 4rem;
}
#div_windowSearchBackButton p {
    color: #ffffff;
    margin-top: 0.5rem;
    margin-left: 0.6rem;
}