#container_searchResults {
    padding-left: 0;
    position: absolute;
    top: 0;
    transition: all 1s ease;
}

#container_searchResults.hidden {
    transform: translateX(-50vw);
    z-index: -1000;
}

#div_searchResults {
    background-color: #ffffff;
    transition: all 1s ease;
    height: 100vh;
    overflow: auto;
}

.div_searchResult {
    color: #6d6d6d;
}

.img_iconContact {
    width: 0px;
    height: 0px;
    background: url('/icon_contact.png');
    padding: 19px; /* Half of PNG size */
    background-repeat: no-repeat;
}

.img_iconContact:hover {
    background: url('/icon_contact_hover.png');
}

@media only screen and (max-width: 540px) {
    #div_searchResults {
        height: 100%;
        overflow: visible;
    }
    

    #container_searchResults {
        position: static;

    }

    #container_searchResults.hidden {
        display: none;
        opacity: 0;
    }
  }