div#root {
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
}

div#container_canvas {
    background-image: url('../../img/background_building.jpg');
    background-repeat: no-repeat;
    background-size: 110%;
    padding-left: 0;
    padding-right: 0;

    transition: all 1s ease;
}

div#container_canvas.backgroundEducate {
    background-image: url('../../img/background_educate.png');
}

div#container_canvas.backgroundMarket {
    background-image: url('../../img/background_market.png');
}

div#container_canvas.backgroundEnable {
    background-image: url('../../img/background_enable.png');
}

div#container_canvas.backgroundDeliver {
    background-image: url('../../img/background_deliver.png');
}

div#container_canvas.backgroundRecover {
    background-image: url('../../img/background_recover.png');
}

div#container_canvas.backgroundCloud {
    background-image: url('../../img/background_cloud.png');
}

div#container_canvas.backgroundCommLifeCycle {
    background-image: url('../../img/background_commLifeCycle.png');
}

div#container_canvas.backgroundTechSol {
    background-image: url('../../img/background_techSol.png');
}

div#container_canvas.backgroundSearch {
    background-image: url('../../img/background_search.png');
    transition: all 0s ease;
}