body
{
    font-family: 'Roboto', sans-serif;
}


div#root {
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
}

div#container_canvas {
    background-image: url(../../static/media/background_building.33bea627.jpg);
    background-repeat: no-repeat;
    background-size: 110%;
    padding-left: 0;
    padding-right: 0;

    -webkit-transition: all 1s ease;

    transition: all 1s ease;
}

div#container_canvas.backgroundEducate {
    background-image: url(../../static/media/background_educate.a60558a9.png);
}

div#container_canvas.backgroundMarket {
    background-image: url(../../static/media/background_market.5b80e235.png);
}

div#container_canvas.backgroundEnable {
    background-image: url(../../static/media/background_enable.cfebdd41.png);
}

div#container_canvas.backgroundDeliver {
    background-image: url(../../static/media/background_deliver.aeab8f1f.png);
}

div#container_canvas.backgroundRecover {
    background-image: url(../../static/media/background_recover.b74af67e.png);
}

div#container_canvas.backgroundCloud {
    background-image: url(../../static/media/background_cloud.383ec186.png);
}

div#container_canvas.backgroundCommLifeCycle {
    background-image: url(../../static/media/background_commLifeCycle.d7f87f0e.png);
}

div#container_canvas.backgroundTechSol {
    background-image: url(../../static/media/background_techSol.7384444b.png);
}

div#container_canvas.backgroundSearch {
    background-image: url(../../static/media/background_search.92c70ab0.png);
    -webkit-transition: all 0s ease;
    transition: all 0s ease;
}
div#div_hole {
    position: relative;
    top: 50%;
    left: 50%;
    width: 691px;
    height: 691px;
    border-radius: 100%;
    box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.64);
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);	/* Offset the object's own dimensions since top/left properties are referencing the upper left corner position */
    -webkit-transition: all 1s ease;
    transition: all 1s ease;
}

div#div_hole.shrink {
    -webkit-transform: translate(-50%, -50%) scale(0.85);
            transform: translate(-50%, -50%) scale(0.85);
}

div#div_hole.blinkToSmall {
    -webkit-animation-duration: 1000ms;
            animation-duration: 1000ms;
    -webkit-animation-name: blinkToSmall;
            animation-name: blinkToSmall;
    -webkit-animation-fill-mode: forwards;
}

div#div_hole.blinkToLarge {
    -webkit-animation-duration: 1000ms;
            animation-duration: 1000ms;
    -webkit-animation-name: blinkToLarge;
            animation-name: blinkToLarge;
    -webkit-animation-fill-mode: forwards;
}

@-webkit-keyframes blinkToSmall {
    0% {
        width: 691px;
        height: 691px;
    }

    50% {
        width: 1px;
        height: 1px;
    }

    100% {
        width: 430px;
        height: 430px;
    }
}

@keyframes blinkToSmall {
    0% {
        width: 691px;
        height: 691px;
    }

    50% {
        width: 1px;
        height: 1px;
    }

    100% {
        width: 430px;
        height: 430px;
    }
}

@-webkit-keyframes blinkToLarge {
    0% {
        width: 430px;
        height: 430px;
    }

    50% {
        width: 1px;
        height: 1px;
    }

    100% {
        width: 691px;
        height: 691px;
    }
}

@keyframes blinkToLarge {
    0% {
        width: 430px;
        height: 430px;
    }

    50% {
        width: 1px;
        height: 1px;
    }

    100% {
        width: 691px;
        height: 691px;
    }
}

#container_left {
    overflow: hidden;
    padding-left: 0;
    padding-right: 0;

    -webkit-transition: all 1s ease;

    transition: all 1s ease;
}

#container_left.pushed-left {
    width: 45%;
}

#container_right {
    right: -110vw;
    background-color: #ffffff;

    -webkit-transition: all 1s ease;

    transition: all 1s ease;
}

#container_right.pushed-left {
    right: 0vw;
    width: 45%;
}


#div_ingramCircleBlue {
    opacity: 0;
    visibility: hidden;

    -webkit-transition: all 1s ease;

    transition: all 1s ease;
}

#div_ingramCircleBlue.show {
    opacity: 1;
    visibility: visible;
}


#img_ingramCircleBlue {
    position: absolute;
    z-index: 1000;	/* Set enough z-index to be above any elements that may be under it */
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);	/* Offset the object's own dimensions since top/left properties are referencing the upper left corner position */

    -webkit-transition: all 0.5s ease;

    transition: all 0.5s ease;
}

#img_ingramCircleBlue.hover {
    -webkit-transform: translate(-50%, -50%) scale(1.10);
            transform: translate(-50%, -50%) scale(1.10);
    cursor: pointer;
}

.arrowLink.hover {
    cursor: pointer;
}

.circleWhiteIcon {
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.circleWhiteIcon:hover, .circleWhiteIcon.hover {
    -webkit-transform: scale(1.25);
            transform: scale(1.25);
}

#arrowLink_recover {
    position: absolute;
    top: 48px;
    left: 60px;
    -webkit-clip-path: polygon(7% 67%, 16% 47%, 32% 27%, 69% 0, 100% 0, 100% 14%, 98% 29%, 78% 33%, 63% 41%, 47% 53%, 35% 71%, 22% 100%, 13% 95%, 0 91%);
    clip-path: polygon(7% 67%, 16% 47%, 32% 27%, 69% 0, 100% 0, 100% 14%, 98% 29%, 78% 33%, 63% 41%, 47% 53%, 35% 71%, 22% 100%, 13% 95%, 0 91%);
}

#arrowLink_educate {
    position: absolute;
    top: 48px;
    left: 351px;
    -webkit-clip-path: polygon(0 0, 19% 0, 43% 10%, 64% 23%, 86% 49%, 97% 66%, 100% 92%, 92% 100%, 78% 100%, 63% 68%, 48% 50%, 29% 37%, 0 28%, 2% 15%);
    clip-path: polygon(0 0, 19% 0, 43% 10%, 64% 23%, 86% 49%, 97% 66%, 100% 92%, 92% 100%, 78% 100%, 63% 68%, 48% 50%, 29% 37%, 0 28%, 2% 15%);
}

#arrowLink_market {
    position: absolute;
    top: 261px;
    left: 487px;
    -webkit-clip-path: polygon(50% 5%, 71% 3%, 94% 0, 100% 9%, 100% 36%, 96% 61%, 37% 100%, 19% 100%, 4% 96%, 0 85%, 47% 51%);
    clip-path: polygon(50% 5%, 71% 3%, 94% 0, 100% 9%, 100% 36%, 96% 61%, 37% 100%, 19% 100%, 4% 96%, 0 85%, 47% 51%);
}

#arrowLink_enable {
    position: absolute;
    top: 539px;
    left: 170px;
    -webkit-clip-path: polygon(11% 0, 40% 35%, 63% 33%, 90% 0, 100% 50%, 92% 73%, 65% 100%, 39% 100%, 18% 86%, 4% 67%, 0 49%, 1% 16%);
    clip-path: polygon(11% 0, 40% 35%, 63% 33%, 90% 0, 100% 50%, 92% 73%, 65% 100%, 39% 100%, 18% 86%, 4% 67%, 0 49%, 1% 16%);
}

#arrowLink_deliver {
    position: absolute;
    top: 257px;
    left: 44px;
    -webkit-clip-path: polygon(0 0, 34% 0, 53% 3%, 46% 19%, 46% 38%, 60% 57%, 76% 68%, 100% 84%, 87% 92%, 76% 100%, 51% 95%, 29% 82%, 6% 59%, 0 42%, 0 23%);
    clip-path: polygon(0 0, 34% 0, 53% 3%, 46% 19%, 46% 38%, 60% 57%, 76% 68%, 100% 84%, 87% 92%, 76% 100%, 51% 95%, 29% 82%, 6% 59%, 0 42%, 0 23%);
}

#popup_educate {
    top: 0px;
    left: 543px;
}

#popup_educate .popupCopyMargin {
    margin-left: -28px;
}

#popup_educate .popupButtonMargin {
    margin-left: -13px;
}

#popup_market {
    top: 474px;
    left: 633px;
}

#popup_market .popupCopyMargin {
    margin-left: -30px;
}

#popup_market .popupButtonMargin {
    margin-left: -12px;
}

#popup_market h2 {
    margin-top: 2px;
}

#popup_enable {
    top: 666px;
    left: 424px;
}

#popup_enable .popupCopyMargin {
    margin-left: -19px;
}

#popup_deliver {
    top: 427px;
    left: -416px;
}

#popup_deliver .popupButtonMargin {
    margin-left: 42px;
}

#popup_recover {
    top: 37px;
    left: -316px;
}

#popup_recover .popupButtonMargin {
    margin-left: 42px;
}
.popup {
    position: absolute;
    color: #ffffff;
    width: 463px;
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translateX(-20px);
            transform: translateX(-20px);

    -webkit-transition: all 0.5s ease;

    transition: all 0.5s ease;
}

.popup.show {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
}

.popup h2 {
    font-size: 1.4rem;
    font-weight: 300;
}

.popup p {
    font-size: 0.9rem;
    font-weight: 300;
}

.popup button {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
    border-radius: 0;
    font-size: 1rem;
    font-weight: 800;
}


#icon_recover {
    position: absolute;
    top: 174px;
    left: 205px;
}

#icon_educate {
    position: absolute;
    top: 177px;
    left: 414px;
}

#icon_market {
    position: absolute;
    top: 383px;
    left: 485px;
}

#icon_enable {
    position: absolute;
    top: 488px;
    left: 315px;
}

#icon_deliver {
    position: absolute;
    top: 368px;
    left: 149px;
}
.infoContainer h1 {
    color: #0082bf;
    font-size: 2.1rem;
}

.infoContainer h2 {
    color: #838484;
    font-size: 1.3rem;
}

.infoContainer h3 {
    color: #0082bf;
    font-weight: 700;
    font-size: 1.3rem;
}

.infoContainer .backButton {
    position: absolute;
    left: -115px;
    top: 25px;

}

.img_backButton {
    cursor: pointer;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.img_backButton.hover {
    cursor: pointer;
    -webkit-transform: translateX(2%) scale(1.10);
            transform: translateX(2%) scale(1.10);
}

.backButton p {
    color: #ffffff;
    margin-top: 0.5rem;
    margin-left: 0.6rem;
}

.img_iconInfo {
    width: 0px;
    height: 0px;
    background: url('/icon_info.png');
    padding: 19px; /* Half of PNG size */
    background-repeat: no-repeat;
}

.img_iconInfo:hover {
    background: url('/icon_info_hover.png');
}

.img_iconContact {
    width: 0px;
    height: 0px;
    background: url('/icon_contact.png');
    padding: 19px; /* Half of PNG size */
    background-repeat: no-repeat;
}

.img_iconContact:hover {
    background: url('/icon_contact_hover.png');
}
#div_ingramCircleWhite {
    opacity: 0;
    visibility: hidden;

    -webkit-transition: all 1s ease;

    transition: all 1s ease;
}

#div_ingramCircleWhite.show {
    opacity: 1;
    visibility: visible;
}

#img_ingramCircleWhite {
    position: absolute;
    z-index: 1000;	/* Set enough z-index to be above any elements that may be under it */
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);	/* Offset the object's own dimensions since top/left properties are referencing the upper left corner position */

    -webkit-transition: all 0.5s ease;

    transition: all 0.5s ease;
}

#img_ingramCircleWhite.hover {
    -webkit-transform: translate(-50%, -50%) scale(1.10);
            transform: translate(-50%, -50%) scale(1.10);
    cursor: pointer;
}

#img_cloudLine {
    position: absolute;
    top: -10px;
    left: -10px;
    height: 250px;

    -webkit-transition: all 1s ease;

    transition: all 1s ease;
}

#img_cloudLine.hide {
    opacity: 0;
    visibility: hidden;
}

#img_techSolLine {
    position: absolute;
    top: 270px;
    left: 220px;
    height: 250px;

    -webkit-transition: all 1s ease;

    transition: all 1s ease;
}

#img_techSolLine.hide {
    opacity: 0;
    visibility: hidden;
}

#img_commLifeCycleLine {
    position: absolute;
    top: -10px;
    left: 240px;
    height: 250px;

    -webkit-transition: all 1s ease;

    transition: all 1s ease;
}

#img_commLifeCycleLine.hide {
    opacity: 0;
    visibility: hidden;
}


#iconlabel_cloud {
    position: absolute;
    top: -90px;
    left: -220px;
}

#iconlabel_cloud .iconLabelTextMargin {
    margin-top: 14px;
}

#iconlabel_commLifeCycle {
    position: absolute;
    top: -90px;
    left: 340px;
}

#iconlabel_techSol {
    position: absolute;
    top: 550px;
    left: 42px;
}

#iconlabel_techSol .iconLabelTextMargin {
    margin-top: 8px;
}

#popup_cloud {
    top: 0px;
    left: -517px;
}

#popup_cloud .popupCopyMargin {
    margin-left: -28px;
}

#popup_cloud .popupButtonMargin {
    margin-left: -13px;
}

#popup_commLifeCycle {
    top: 0px;
    left: 460px;
}

#popup_commLifeCycle .popupCopyMargin {
    margin-left: -28px;
}

#popup_commLifeCycle .popupButtonMargin {
    margin-left: -13px;
}

#popup_techSol {
    top: 480px;
    left: 260px;
}

#popup_techSol .popupCopyMargin {
    margin-left: -28px;
}

#popup_techSol .popupButtonMargin {
    margin-left: -13px;
}

.iconLabel {
    position: absolute;
    color: #ffffff;
    width: 463px;

    -webkit-transition: all 1s ease;

    transition: all 1s ease;
}

.iconLabel h2 {
    font-size: 1.4rem;
    font-weight: 300;
}

.iconLabel.hide {
    opacity: 0;
    visibility: hidden;
}
#container_windowSearch {
    -webkit-transition: all 1s ease;
    transition: all 1s ease;
}

#container_windowSearch.hidden {
    opacity: 0;
    visibility: hidden;
}

#div_mainSearch {
    -webkit-transition: all 1s ease;
    transition: all 1s ease;
}

#div_mainSearch.hidden {
    opacity: 0;
}

input#input_mainSearch.form-control {
    background-color: transparent;
    color: #ffffff;
    font-size: 2.0rem;
    border-radius: 0;
    border-color: #444444;
    border-right: none;
}

input#input_mainSearch.form-control:focus {
    background-color: transparent;
    color: #ffffff;
}

input#input_mainSearch.form-control::-webkit-input-placeholder {
    color: #a5a5a5;
}

input#input_mainSearch.form-control::-ms-input-placeholder {
    color: #a5a5a5;
}

input#input_mainSearch.form-control::placeholder {
    color: #a5a5a5;
}

.btn-search {
    color: #000000;
    background: #0077d4;
    border-radius: 0;
}

input#input_secondarySearch {
    border-top: transparent;
    border-left: transparent;
    border-right: transparent;
    border-bottom: 2px solid;
    border-radius: 0;
}

input#input_secondarySearch.form-control:focus {
    border-color: transparent;
    box-shadow: none;
    border-bottom: 2px solid;
} 

#img_searchIcon {
    margin-left: -1.2rem;
}

#div_backButton {
    position: absolute;
    right: -8rem;
    top: 4rem;
    
}

#div_backButton img:hover, #div_windowSearchBackButton img:hover {
    cursor: pointer;
}

#div_backButton p {
    color: #ffffff;
    margin-left: 5.6rem;
    margin-top: 0.5rem;
}

#div_backButton p:hover {
    cursor: pointer;
}

.input-group-text {
    background: none;
    border: 1px solid #444444;
    border-left: none;
}

#div_windowSearchBackButton {
    position: absolute;
    top: 4rem;
    left: 4rem;
}
#div_windowSearchBackButton p {
    color: #ffffff;
    margin-top: 0.5rem;
    margin-left: 0.6rem;
}
#container_searchResults {
    padding-left: 0;
    position: absolute;
    top: 0;
    -webkit-transition: all 1s ease;
    transition: all 1s ease;
}

#container_searchResults.hidden {
    -webkit-transform: translateX(-50vw);
            transform: translateX(-50vw);
    z-index: -1000;
}

#div_searchResults {
    background-color: #ffffff;
    -webkit-transition: all 1s ease;
    transition: all 1s ease;
    height: 100vh;
    overflow: auto;
}

.div_searchResult {
    color: #6d6d6d;
}

.img_iconContact {
    width: 0px;
    height: 0px;
    background: url('/icon_contact.png');
    padding: 19px; /* Half of PNG size */
    background-repeat: no-repeat;
}

.img_iconContact:hover {
    background: url('/icon_contact_hover.png');
}

@media only screen and (max-width: 540px) {
    #div_searchResults {
        height: 100%;
        overflow: visible;
    }
    

    #container_searchResults {
        position: static;

    }

    #container_searchResults.hidden {
        display: none;
        opacity: 0;
    }
  }
#div_mobileBackgroundLayer
{
    -webkit-transition: all 1s ease;
    transition: all 1s ease;
}

#div_mobileBackgroundLayer.background-home {
    background-image: url(../../static/media/mobile_background.b74af67e.png);
    background-repeat: no-repeat;
    background-size: 175%;
    background-position-x: 0px;
    background-position-y: 0px;    
}

#div_mobileBackgroundLayer.background-white {
    background-color: #ffffff;
}

#container_mobileLayout_header {
    padding-left: 0;
    padding-right: 0;
}

.black-background-tint {
    background-color: rgba(0, 0, 0, 0.5);
}

div.navbar-collapse {
    padding-top: 4rem;
    padding-bottom: 4rem;
    text-align: center;
}

#container_mobileLayout_footer {
    background-color: #000000;
    color: #ffffff;
    font-size: 0.875rem;
}

#container_mobileLayout_copyFooter {
    background-color: #ffffff;
    font-size: 0.875rem;
}

a.nav-link span {
    color: #ffffff;
    font-size: 1.5rem;
    font-weight: 500;
}

@media only screen and (max-width: 540px) {
    div#root {
        height: auto;
        width: auto;
        position: static;
    }
  }
#container_pillars {
    background-color: #ffffff;    
}

#container_pillars p {
    font-weight: 600;    
    font-size: 1.125rem;
}

.gray-border-bottom {
    border-bottom: 1px solid #767676;
}

#div_mobile_hole_base {
    padding-top: 100px;
    padding-bottom: 10px;
    /*mix-blend-mode: hard-light;*/
}

#div_mobile_hole_base p {
    color: #ffffff;
}

/*
#div_mobile_hole {
    width: 200px;
    height: 200px;
    border-radius: 999px;
    background-color: gray;
}*/

#img_mobileIngramCircle {
    
}
@media only screen and (max-width: 540px) {

    input#input_mobile_mainSearch.form-control {
        background-color: transparent;
        color: #ffffff;
        font-size: 1.125rem;
        border-radius: 0;
        border-color: #444444;
    }
}
.container_pillarBoxes {
    background-color: #000000;
}

.container_pillarBoxes p {
    color: #ffffff;
    font-size: 1.625rem;
    weight: 600;
}

.container_pillarBoxes .border-left {
    border-left: 1px solid #6d6d6d;
}

.container_pillarBoxes .border-right {
    border-right: 1px solid #6d6d6d;
}

.container_pillarBoxes .border-top {
    border-top: 1px solid #6d6d6d;
}

.container_pillarBoxes .border-bottom {
    border-bottom: 1px solid #6d6d6d;
}
h1 {
    font-size: 2rem;
    font-weight: 600;
}

h2 {
    font-size: 1.25rem;
    font-weight: 600;
}

.text-color-gray {
    color: #838383;
}

.text-color-blue {
    color: #0080be;
}


