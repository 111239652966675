h1 {
    font-size: 2rem;
    font-weight: 600;
}

h2 {
    font-size: 1.25rem;
    font-weight: 600;
}

.text-color-gray {
    color: #838383;
}

.text-color-blue {
    color: #0080be;
}

