.infoContainer h1 {
    color: #0082bf;
    font-size: 2.1rem;
}

.infoContainer h2 {
    color: #838484;
    font-size: 1.3rem;
}

.infoContainer h3 {
    color: #0082bf;
    font-weight: 700;
    font-size: 1.3rem;
}

.infoContainer .backButton {
    position: absolute;
    left: -115px;
    top: 25px;

}

.img_backButton {
    cursor: pointer;
    transition: all 0.5s ease;
}

.img_backButton.hover {
    cursor: pointer;
    transform: translateX(2%) scale(1.10);
}

.backButton p {
    color: #ffffff;
    margin-top: 0.5rem;
    margin-left: 0.6rem;
}

.img_iconInfo {
    width: 0px;
    height: 0px;
    background: url('/icon_info.png');
    padding: 19px; /* Half of PNG size */
    background-repeat: no-repeat;
}

.img_iconInfo:hover {
    background: url('/icon_info_hover.png');
}

.img_iconContact {
    width: 0px;
    height: 0px;
    background: url('/icon_contact.png');
    padding: 19px; /* Half of PNG size */
    background-repeat: no-repeat;
}

.img_iconContact:hover {
    background: url('/icon_contact_hover.png');
}