div#div_hole {
    position: relative;
    top: 50%;
    left: 50%;
    width: 691px;
    height: 691px;
    border-radius: 100%;
    box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.64);
    transform: translate(-50%, -50%);	/* Offset the object's own dimensions since top/left properties are referencing the upper left corner position */
    transition: all 1s ease;
}

div#div_hole.shrink {
    transform: translate(-50%, -50%) scale(0.85);
}

div#div_hole.blinkToSmall {
    animation-duration: 1000ms;
    animation-name: blinkToSmall;
    -webkit-animation-fill-mode: forwards;
}

div#div_hole.blinkToLarge {
    animation-duration: 1000ms;
    animation-name: blinkToLarge;
    -webkit-animation-fill-mode: forwards;
}

@keyframes blinkToSmall {
    0% {
        width: 691px;
        height: 691px;
    }

    50% {
        width: 1px;
        height: 1px;
    }

    100% {
        width: 430px;
        height: 430px;
    }
}

@keyframes blinkToLarge {
    0% {
        width: 430px;
        height: 430px;
    }

    50% {
        width: 1px;
        height: 1px;
    }

    100% {
        width: 691px;
        height: 691px;
    }
}

#container_left {
    overflow: hidden;
    padding-left: 0;
    padding-right: 0;

    transition: all 1s ease;
}

#container_left.pushed-left {
    width: 45%;
}

#container_right {
    right: -110vw;
    background-color: #ffffff;

    transition: all 1s ease;
}

#container_right.pushed-left {
    right: 0vw;
    width: 45%;
}

