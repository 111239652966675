.iconLabel {
    position: absolute;
    color: #ffffff;
    width: 463px;

    transition: all 1s ease;
}

.iconLabel h2 {
    font-size: 1.4rem;
    font-weight: 300;
}

.iconLabel.hide {
    opacity: 0;
    visibility: hidden;
}