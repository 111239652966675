
#icon_recover {
    position: absolute;
    top: 174px;
    left: 205px;
}

#icon_educate {
    position: absolute;
    top: 177px;
    left: 414px;
}

#icon_market {
    position: absolute;
    top: 383px;
    left: 485px;
}

#icon_enable {
    position: absolute;
    top: 488px;
    left: 315px;
}

#icon_deliver {
    position: absolute;
    top: 368px;
    left: 149px;
}