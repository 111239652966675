#div_mobileBackgroundLayer
{
    transition: all 1s ease;
}

#div_mobileBackgroundLayer.background-home {
    background-image: url('./img/mobile_background.png');
    background-repeat: no-repeat;
    background-size: 175%;
    background-position-x: 0px;
    background-position-y: 0px;    
}

#div_mobileBackgroundLayer.background-white {
    background-color: #ffffff;
}

#container_mobileLayout_header {
    padding-left: 0;
    padding-right: 0;
}

.black-background-tint {
    background-color: rgba(0, 0, 0, 0.5);
}

div.navbar-collapse {
    padding-top: 4rem;
    padding-bottom: 4rem;
    text-align: center;
}

#container_mobileLayout_footer {
    background-color: #000000;
    color: #ffffff;
    font-size: 0.875rem;
}

#container_mobileLayout_copyFooter {
    background-color: #ffffff;
    font-size: 0.875rem;
}

a.nav-link span {
    color: #ffffff;
    font-size: 1.5rem;
    font-weight: 500;
}

@media only screen and (max-width: 540px) {
    div#root {
        height: auto;
        width: auto;
        position: static;
    }
  }