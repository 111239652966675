#div_ingramCircleBlue {
    opacity: 0;
    visibility: hidden;

    transition: all 1s ease;
}

#div_ingramCircleBlue.show {
    opacity: 1;
    visibility: visible;
}


#img_ingramCircleBlue {
    position: absolute;
    z-index: 1000;	/* Set enough z-index to be above any elements that may be under it */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);	/* Offset the object's own dimensions since top/left properties are referencing the upper left corner position */

    transition: all 0.5s ease;
}

#img_ingramCircleBlue.hover {
    transform: translate(-50%, -50%) scale(1.10);
    cursor: pointer;
}

.arrowLink.hover {
    cursor: pointer;
}

.circleWhiteIcon {
    transition: all 0.5s ease;
}

.circleWhiteIcon:hover, .circleWhiteIcon.hover {
    transform: scale(1.25);
}

#arrowLink_recover {
    position: absolute;
    top: 48px;
    left: 60px;
    -webkit-clip-path: polygon(7% 67%, 16% 47%, 32% 27%, 69% 0, 100% 0, 100% 14%, 98% 29%, 78% 33%, 63% 41%, 47% 53%, 35% 71%, 22% 100%, 13% 95%, 0 91%);
    clip-path: polygon(7% 67%, 16% 47%, 32% 27%, 69% 0, 100% 0, 100% 14%, 98% 29%, 78% 33%, 63% 41%, 47% 53%, 35% 71%, 22% 100%, 13% 95%, 0 91%);
}

#arrowLink_educate {
    position: absolute;
    top: 48px;
    left: 351px;
    -webkit-clip-path: polygon(0 0, 19% 0, 43% 10%, 64% 23%, 86% 49%, 97% 66%, 100% 92%, 92% 100%, 78% 100%, 63% 68%, 48% 50%, 29% 37%, 0 28%, 2% 15%);
    clip-path: polygon(0 0, 19% 0, 43% 10%, 64% 23%, 86% 49%, 97% 66%, 100% 92%, 92% 100%, 78% 100%, 63% 68%, 48% 50%, 29% 37%, 0 28%, 2% 15%);
}

#arrowLink_market {
    position: absolute;
    top: 261px;
    left: 487px;
    -webkit-clip-path: polygon(50% 5%, 71% 3%, 94% 0, 100% 9%, 100% 36%, 96% 61%, 37% 100%, 19% 100%, 4% 96%, 0 85%, 47% 51%);
    clip-path: polygon(50% 5%, 71% 3%, 94% 0, 100% 9%, 100% 36%, 96% 61%, 37% 100%, 19% 100%, 4% 96%, 0 85%, 47% 51%);
}

#arrowLink_enable {
    position: absolute;
    top: 539px;
    left: 170px;
    -webkit-clip-path: polygon(11% 0, 40% 35%, 63% 33%, 90% 0, 100% 50%, 92% 73%, 65% 100%, 39% 100%, 18% 86%, 4% 67%, 0 49%, 1% 16%);
    clip-path: polygon(11% 0, 40% 35%, 63% 33%, 90% 0, 100% 50%, 92% 73%, 65% 100%, 39% 100%, 18% 86%, 4% 67%, 0 49%, 1% 16%);
}

#arrowLink_deliver {
    position: absolute;
    top: 257px;
    left: 44px;
    -webkit-clip-path: polygon(0 0, 34% 0, 53% 3%, 46% 19%, 46% 38%, 60% 57%, 76% 68%, 100% 84%, 87% 92%, 76% 100%, 51% 95%, 29% 82%, 6% 59%, 0 42%, 0 23%);
    clip-path: polygon(0 0, 34% 0, 53% 3%, 46% 19%, 46% 38%, 60% 57%, 76% 68%, 100% 84%, 87% 92%, 76% 100%, 51% 95%, 29% 82%, 6% 59%, 0 42%, 0 23%);
}

#popup_educate {
    top: 0px;
    left: 543px;
}

#popup_educate .popupCopyMargin {
    margin-left: -28px;
}

#popup_educate .popupButtonMargin {
    margin-left: -13px;
}

#popup_market {
    top: 474px;
    left: 633px;
}

#popup_market .popupCopyMargin {
    margin-left: -30px;
}

#popup_market .popupButtonMargin {
    margin-left: -12px;
}

#popup_market h2 {
    margin-top: 2px;
}

#popup_enable {
    top: 666px;
    left: 424px;
}

#popup_enable .popupCopyMargin {
    margin-left: -19px;
}

#popup_deliver {
    top: 427px;
    left: -416px;
}

#popup_deliver .popupButtonMargin {
    margin-left: 42px;
}

#popup_recover {
    top: 37px;
    left: -316px;
}

#popup_recover .popupButtonMargin {
    margin-left: 42px;
}